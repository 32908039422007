import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "@src/components/ui/accordion";
import { cityNamesArray } from "@src/lib/plans";
import { footerCities } from "./data";

export const CityList = () => {
  const cities = cityNamesArray.map((city) =>
    city === "Bangalore" ? "Bengaluru" : city
  );

  const products = ["copper", "alkaline", "mineral"] as const;

  return (
    <div className="bg-[linear-gradient(105deg,_#F8F0FF_7.28%,_#F2F6FF_58.8%,_#E4F7FF_109.26%)]">
      <div className="md:container py-10 px-4 lg:px-0 ">
        <h3 className="text-[20px] font-bold mb-4 lg:mb-10 lg:text-center text-[#14142B]">
          Cities We Are Present In
        </h3>

        {/* Desktop city list */}
        <div className="container hidden gap-4 justify-around lg:flex">
          {products.map((product) => {
            return (
              <div className="flex flex-col gap-5">
                {footerCities.map(({ title, path }, index) => {
                  return (
                    <a
                      href={path
                        .replace("[city]", cities[index])
                        .replace("[product]", product)
                        .toLowerCase()}
                      className="font-medium capitalize text-[#4E4B66]"
                    >
                      {title.replace(
                        "[product]",
                        product === "mineral" ? "RO+" : product
                      )}
                    </a>
                  );
                })}
              </div>
            );
          })}
        </div>

        {/* Mobile accordion */}
        <Accordion
          type="multiple"
          className="container flex flex-col gap-4 lg:hidden px-0"
        >
          {products.map((product) => {
            return (
              <AccordionItem
                value={product}
                className="border-x-0 border-t-0 border-b-[#14142B] border-b-[0.6px]"
              >
                <AccordionTrigger className="capitalize no-underline font-bold hover:no-underline text-base text-[#14142B]">
                  {product} water purifiers
                </AccordionTrigger>
                <AccordionContent className="flex flex-col gap-4">
                  {footerCities.map(({ title, path }, index) => {
                    return (
                      <a
                        href={path
                          .replace("[city]", cities[index])
                          .replace("[product]", product)
                          .toLowerCase()}
                        className="font-medium capitalize text-base text-[#4E4B66] "
                      >
                        {title.replace(
                          "[product]",
                          product === "mineral" ? "RO+" : product
                        )}
                      </a>
                    );
                  })}
                </AccordionContent>
              </AccordionItem>
            );
          })}
        </Accordion>
      </div>
    </div>
  );
};
