import { linkSections } from "./data";
import { Icons, Logos } from "@src/components/icons";
import { Images } from "@src/components/images";
import LeadFormPopupTriggerButton from "../LeadFormSection/LeadFormPopup/LeadFormPopupTriggerButton";
import { useLinkHandler } from "@src/hooks/useLinkHandler";

// Link section data

export const Footer = () => {
  const handleLinkClick = useLinkHandler();
  return (
    <footer className="!bg-primary-primary pt-[58px] px-4 text-white md:p-8 pb-[100px] [&_a]:cursor-pointer">
      <div className="md:container md:mx-auto">
        <div className="flex flex-col lg:flex-row justify-between gap-2 lg:gap-16">
          <div className="mb-8 lg:mb-0 md:max-w-[265px]">
            <Logos
              name="dp-white"
              className="w-[223px] h-[50px] md:w-[158px] md:h-[36px]"
            />
            <p className="text-xs font-bold my-3">
              Smart Purifiers on Rent. Free Maintenance for Life.
            </p>
            <p className="text-xs mb-4 font-medium leading-tight tracking-tight">
              #9/A, Chukki complex, 19th Main Rd, Sector 3, HSR layout,
              Bengaluru, Karnataka 560102
            </p>
            <div className="hidden lg:block">
              <SocialsLinks />
            </div>
          </div>

          <div className="flex flex-col gap-[34px] lg:mt-4 md:grid lg:grid-cols-[repeat(3,auto)] md:gap-10 xl:gap-20">
            <div className="">
              <h3 className="font-medium md:font-semibold text-xl mb-4 text-accent-accent whitespace-pre">
                Premium Drinking <br className="hidden xl:block" />
                Water
              </h3>
              <ul>
                {linkSections.premiumDrinkingWater.map((link, index) => (
                  <li key={index} className="mb-[8px]">
                    <a
                      onClick={() => handleLinkClick(link)}
                      className="font-medium hover:underline"
                    >
                      {link.title}
                    </a>
                  </li>
                ))}
              </ul>
              <h3 className="font-bold mt-10 md:mt-6 mb-4 text-accent-accent">
                Refer & Earn
              </h3>
              <ul>
                {linkSections.referAndEarn.map((link, index) => (
                  <li key={index} className="mb-2">
                    <a
                      onClick={() => handleLinkClick(link)}
                      className="font-medium hover:underline"
                    >
                      {link.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="">
              <h3 className="font-medium md:font-semibold text-xl mb-4 text-accent-accent">
                Explore
              </h3>
              <ul>
                {linkSections.explore.map((link, index) => (
                  <li key={index} className="mb-2">
                    <a
                      onClick={() => handleLinkClick(link)}
                      className="font-medium hover:underline"
                    >
                      {link.title}
                    </a>
                  </li>
                ))}
              </ul>
              <div className="hidden md:block">
                <h3 className="font-bold mt-6 mb-4 text-accent-accent">
                  Terms
                </h3>
                <ul>
                  {linkSections.terms.map((link, index) => (
                    <li key={index} className="mb-2">
                      <a
                        onClick={() => handleLinkClick(link)}
                        className="font-medium hover:underline"
                      >
                        {link.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="col-span-2 lg:col-span-1  ">
              <h3 className="font-medium md:font-semibold text-xl mb-4 text-accent-accent">
                Cities
              </h3>
              <ul>
                {linkSections.cities.map((link, index) => (
                  <li key={index} className="mb-2">
                    <a
                      onClick={() => handleLinkClick(link)}
                      className="font-medium hover:underline"
                    >
                      {link.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div className="block md:hidden">
              <h3 className="font-medium md:font-semibold text-xl mb-4 text-accent-accent">
                Terms
              </h3>
              <ul>
                {linkSections.terms.map((link, index) => (
                  <li key={index} className="mb-2">
                    <a
                      onClick={() => handleLinkClick(link)}
                      className="font-medium hover:underline"
                    >
                      {link.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="lg:hidden mt-14">
            <SocialsLinks />
          </div>
        </div>

        <div className=" text-sm text-center lg:text-left mt-10 md:mb-20">
          © 2023 DrinkPrime. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

const SocialsLinks = () => {
  return (
    <>
      <div className="flex space-x-4 mb-4 lg:mt-[86px] justify-between sm:justify-center px-4">
        <a href="https://twitter.com/drinkprime?lang=en" target="_blank">
          <Icons name="twitter" className="h-[25px]" color="#FFFF" />
        </a>
        <a
          href="https://www.linkedin.com/company/drinkprime/?originalSubdomain=in"
          target="_blank"
        >
          <Icons name="linkedin" className="h-[25px]" color="#FFFF" />
        </a>
        <a href="https://www.facebook.com/DrinkPrime/" target="_blank">
          <Icons name="facebook" className="h-[25px]" color="#FFFF" />
        </a>
        <a href="https://www.instagram.com/drink_prime/?hl=en" target="_blank">
          <Icons name="instagram" className="h-[25px]" color="#FFFF" />
        </a>
        <LeadFormPopupTriggerButton
          className="h-[25px]"
          formName="homepage/footer-whatsapp-cta"
          nextRoute={() => {
            setTimeout(() => (window.location.href = "https://wa.link/61q8wq"));
          }}
        >
          <Icons name="whatsapp" className="h-[25px]" color="#FFFF" />
        </LeadFormPopupTriggerButton>
      </div>
      <div className="flex space-x-4 mt-9 justify-center">
        <a
          href="https://apps.apple.com/in/app/drinkprime/id1358111884"
          target="_blank"
        >
          <Images
            name="misc/apple-appstore"
            className="w-[126px] h-[35px]"
            alt="Apple app store logo"
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=waterwala.com.prime"
          target="_blank"
        >
          <Images
            name="misc/google-playstore"
            className="w-[126px] h-[35px]"
            alt="Google play store logo"
          />
        </a>
      </div>
    </>
  );
};
