import { useContext, useEffect } from "react";
import {
  AllRechargeIdType,
  getItemFromLocalStorage,
  Model,
} from "../../../lib/plans";
import { cn } from "../../../lib/utils";
import LeadFormPopupTriggerButton from "../../LeadFormSection/LeadFormPopup/LeadFormPopupTriggerButton";
import { PlanContext } from "./planSectionContext";
import { CityPopupContext } from "../../../pages/homepage";

export default function ButtonGroup({
  className,
  modelState,
  planId,
}: {
  className?: string;
  setIsFormPopup: React.Dispatch<React.SetStateAction<boolean>>;
  modelState: Model;
  planId: AllRechargeIdType;
}) {
  const planData = useContext(PlanContext);
  const { setIsCityPopupOpen, setOnDialogCloseCallback } =
    useContext(CityPopupContext);
  useEffect(() => {
    const { setCategoryState, setDurationState } = planData;
    setCategoryState(
      modelState === "ALIVE"
        ? "SOLO"
        : modelState === "LITE"
        ? "BASIC"
        : "STANDARD"
    );
    setDurationState("28");
    // if (modelState === "ALIVE") {
    //   const planId1 = defaultCopperPlans["SOLO"]["28"].rechargeId;
    //   setPlanId(planId1);
    // }
  }, [modelState]);
  const mapModelState = {
    ALIVE: "copper",
    LITE: "mineral",
    ALKALINE: "alkaline",
  };

  return (
    <div
      className={cn(
        "mb-4 sm:px-7 flex -sm:flex-col-reverse gap-3 sm:gap-4 w-full px-0.5",
        "nth-n:flex-1 nth-n:min-h-[48px]",
        "font-semibold text-sm sm:text-[17px]",
        className
      )}
    >
      <button
        type="button"
        className={cn(
          "rounded-full outline outline-1 outline-primary-primary bg-white text-primary-primary"
        )}
        onClick={() => {
          const cityObjFromPopup = getItemFromLocalStorage(
            "selected-city-popup"
          );
          let city;
          if (cityObjFromPopup) {
            city = cityObjFromPopup.city;
            if (city == "Bangalore") city = "Bengaluru";
            window.location.href = `${
              import.meta.env.VITE_ORIGIN
            }/app/${city}/ro-uv-${
              mapModelState[modelState]
            }-water-purifier-on-rent`;
          } else {
            setIsCityPopupOpen(true);
            setOnDialogCloseCallback(() => () => {
              const cityObjFromPopup = getItemFromLocalStorage(
                "selected-city-popup"
              );
              let city;
              if (cityObjFromPopup) {
                city = cityObjFromPopup.city;
                if (city == "Bangalore") city = "Bengaluru";
                window.location.href = `${
                  import.meta.env.VITE_ORIGIN
                }/app/${city}/ro-uv-${
                  mapModelState[modelState]
                }-water-purifier-on-rent`;
              }
            });
          }
        }}
      >
        Know More
      </button>
      <LeadFormPopupTriggerButton
        formName="homepage/plan-section"
        className="rounded-full bg-primary-primary text-off-white sm:text-white font-semibold"
        nextRoute={
          modelState === "ALIVE" || modelState === "ALKALINE"
            ? (str: string) => {
                window.location.href = `https://drinkprime.in/app/checkout/address?model=${modelState}&planId=${planId}&${str}`;
              }
            : "thankYou"
        }
      >
        Subscribe Now
      </LeadFormPopupTriggerButton>
    </div>
  );
}
