export const linkSections = {
  premiumDrinkingWater: [
    // { title: "Plans", path: "#plans-section" },
    {
      title: "Plans",
      path: [
        "#plan_section",
        "#pay-per-litre",
        "#plans-section",
        "#rent-now-sticky-scroll",
        "/app/checkout/chooseplan",
      ],
    },
    {
      title: "How it works",
      path: ["#how-it-works", "#how-it-works-section", "/app/how-it-works"],
    },
    { title: "DrinkPrime advantage", path: "#drinkprime-advantage-section" },
    { title: "Customer stories", path: "#testimonials-section" },
    {
      title: "Our Products",
      path: [
        "#product-plan-section",
        "/app/bengaluru/ro-uv-copper-water-purifier-on-rent",
      ],
    },
    // { title: "Customer stories", path: "#testimonials-section" },
    // { title: "Get 7-days risk free trial", path: "#free-trial-section" },
  ],
  explore: [
    { title: "About", path: "https://www.drinkprime.in/about" },
    { title: "Careers", path: "https://drinkprime.in/careers" },
    { title: "Blog", path: "https://www.drinkprime.in/blog" },
    { title: "FAQs", path: ["#faqs", "#faqs-section", "/app/faqs"] },
    { title: "Contact", path: ["#contact-us", "/app/contact-us"] },
  ],
  referAndEarn: [
    { title: "Refer now", path: "https://drinkprime.in/app/refer_earn" },
  ],
  cities: [
    {
      title: "Water purifier in Bengaluru",
      path: "https://drinkprime.in/app/bengaluru/ro-uv-copper-water-purifier-on-rent",
    },
    {
      title: "Water purifier in Hyderabad",
      path: "https://drinkprime.in/app/hyderabad/ro-uv-copper-water-purifier-on-rent",
    },
    {
      title: "Water purifier in Delhi",
      path: "https://drinkprime.in/app/delhi/ro-uv-copper-water-purifier-on-rent",
    },
    {
      title: "Water purifier in Gurgaon",
      path: "https://drinkprime.in/app/gurugram/ro-uv-copper-water-purifier-on-rent",
    },
    {
      title: "Water purifier in Ghaziabad",
      path: "https://drinkprime.in/app/ghaziabad/ro-uv-copper-water-purifier-on-rent",
    },
    {
      title: "Water purifier in Faridabad",
      path: "https://drinkprime.in/app/faridabad/ro-uv-copper-water-purifier-on-rent",
    },
    {
      title: "Water purifier in Noida",
      path: "https://drinkprime.in/app/noida/ro-uv-copper-water-purifier-on-rent",
    },
    {
      title: "Water purifier in Mumbai",
      path: "https://drinkprime.in/app/mumbai/ro-uv-copper-water-purifier-on-rent",
    },
    {
      title: "Water purifier in Pune",
      path: "https://drinkprime.in/app/pune/ro-uv-copper-water-purifier-on-rent",
    },
  ],
  terms: [
    {
      title: "Privacy Policy",
      path: "https://www.drinkprime.in/app/privacy-policy",
    },
    {
      title: "Terms of Use",
      path: "https://www.drinkprime.in/app/terms-of-use",
    },
  ],
};
