// plan product images
import lp114_butt0_prod1 from "@src/vitehomepageassets/images/plan-product/lp114_butt0_product1.webp";
import lp114_butt0_prod2 from "@src/vitehomepageassets/images/plan-product/lp114_butt0_product2.webp";
import lp114_butt0_prod3 from "@src/vitehomepageassets/images/plan-product/lp114_butt0_product3.webp";
import lp114_butt0_prod4 from "@src/vitehomepageassets/images/plan-product/lp114_butt0_product4.webp";
import lp114_butt0_prod5 from "@src/vitehomepageassets/images/plan-product/lp114_butt0_product5.webp";
import lp114_butt0_prod6 from "@src/vitehomepageassets/images/plan-product/lp114_butt0_product6.webp";

import lp114_butt1_prod1 from "@src/vitehomepageassets/images/plan-product/lp114_butt1_product1.webp";
import lp114_butt1_prod2 from "@src/vitehomepageassets/images/plan-product/lp114_butt1_product2.webp";
import lp114_butt1_prod3 from "@src/vitehomepageassets/images/plan-product/lp114_butt1_product3.webp";
import lp114_butt1_prod4 from "@src/vitehomepageassets/images/plan-product/lp114_butt1_product4.webp";
import lp114_butt1_prod5 from "@src/vitehomepageassets/images/plan-product/lp114_butt1_product5.webp";

import alkaline_featureImg1 from "@src/vitehomepageassets/images/plan-product/alkaline_product_feature_img1.webp";
import alkaline_featureImg2 from "@src/vitehomepageassets/images/plan-product/alkaline_product_feature_img2.webp";
import alkaline_featureImg3 from "@src/vitehomepageassets/images/plan-product/alkaline_product_feature_img3.webp";
import alkaline_featureImg4 from "@src/vitehomepageassets/images/plan-product/alkaline_product_feature_img4.webp";

// app related images
import appFeatureHelpdesk from "@src/vitehomepageassets/images/dpApp/app-feature-helpdesk.webp";
import appFeatureRecharge from "@src/vitehomepageassets/images/dpApp/app-feature-recharge.webp";
import appFeaturePlan from "@src/vitehomepageassets/images/dpApp/app-feature-plan.webp";
import appFeatureReferAndEarn from "@src/vitehomepageassets/images/dpApp/app-feature-refer-and-earn.webp";
import appFeatureWaterReport from "@src/vitehomepageassets/images/dpApp/app-feature-water-report.webp";

// cities images
import banglore from "@src/vitehomepageassets/images/cities/bangalore.webp";
import delhi from "@src/vitehomepageassets/images/cities/delhi.webp";
import faridabad from "@src/vitehomepageassets/images/cities/faridabad.webp";
import ghaziabad from "@src/vitehomepageassets/images/cities/ghaziabad.webp";
import gurgaon from "@src/vitehomepageassets/images/cities/gurgaon.webp";
import hyderabad from "@src/vitehomepageassets/images/cities/hyderabad.webp";
import mumbai from "@src/vitehomepageassets/images/cities/mumbai.webp";
import noida from "@src/vitehomepageassets/images/cities/noida.webp";
import pune from "@src/vitehomepageassets/images/cities/pune.webp";

// background images
import cityShowcaseBgDesk from "@src/vitehomepageassets/images/background/city-showcase-bg.webp";
import testimonialsBgDesk from "@src/vitehomepageassets/images/background/testimonial-bg-desk.webp";
import testimonialsBgMobile from "@src/vitehomepageassets/images/background/testimonial-bg-mobile.webp";

// awards images
// import award1 from "@src/vitehomepageassets/images/awards/award_img1.webp";
import award1 from "@src/vitehomepageassets/images/awards/award_img2.webp";
import award2 from "@src/vitehomepageassets/images/awards/award_img3.webp";
import award3 from "@src/vitehomepageassets/images/awards/award_img4.webp";
import award4 from "@src/vitehomepageassets/images/awards/award_img5.webp";
import award5 from "@src/vitehomepageassets/images/awards/award_img6.webp";
import award6 from "@src/vitehomepageassets/images/awards/award_img7.webp";
import award7 from "@src/vitehomepageassets/images/awards/award_img8.webp";
import award8 from "@src/vitehomepageassets/images/awards/award_img9.webp";

// investor images
import investor1 from "@src/vitehomepageassets/images/investors/investor_img1.webp";
import investor2 from "@src/vitehomepageassets/images/investors/investor_img2.webp";
import investor3 from "@src/vitehomepageassets/images/investors/investor_img3.webp";
import investor4 from "@src/vitehomepageassets/images/investors/investor_img4.webp";
import investor5 from "@src/vitehomepageassets/images/investors/investor_img5.webp";
import investor6 from "@src/vitehomepageassets/images/investors/investor_img6.webp";

// uncategorised images
import googplePlaystore from "@src/vitehomepageassets/images/uncategorised/google-playstore.webp";
import appStore from "@src/vitehomepageassets/images/uncategorised/apple-appstore.webp";
import waterCan from "@src/vitehomepageassets/images/uncategorised/water_can.webp";
import otherPurifier from "@src/vitehomepageassets/images/uncategorised/other_purifier.webp";

// icons
import WhatsappLogo from "@src/vitehomepageassets/images/icons/whatsapp-logo.webp";

// hero
import bengaluruDesktop from "@src/vitehomepageassets/images/hero/bengaluru-desktop.webp";
import bengaluruMobile from "@src/vitehomepageassets/images/hero/bengaluru-mobile.webp";
import otherCitiesDesktop from "@src/vitehomepageassets/images/hero/otherCities-desktop.webp";
import otherCitiesMobile from "@src/vitehomepageassets/images/hero/otherCities-mobile.webp";
import allBlackHeroDesktop from "@src/vitehomepageassets/images/hero/hero_all_black_desk.webp";
import allBlackHeroMobile from "@src/vitehomepageassets/images/hero/hero_all_black_mobile.webp";

export const planProductImages = {
  "copper/image1": lp114_butt0_prod1,
  "copper/image2": lp114_butt0_prod2,
  "copper/image3": lp114_butt0_prod3,
  "copper/image4": lp114_butt0_prod4,
  "copper/image5": lp114_butt0_prod5,
  "copper/image6": lp114_butt0_prod6,

  "mineral/image1": lp114_butt1_prod1,
  "mineral/image2": lp114_butt1_prod2,
  "mineral/image3": lp114_butt1_prod3,
  "mineral/image4": lp114_butt1_prod4,
  "mineral/image5": lp114_butt1_prod5,

  "alkaline/image1": alkaline_featureImg1,
  "alkaline/image2": alkaline_featureImg2,
  "alkaline/image3": alkaline_featureImg3,
  "alkaline/image4": alkaline_featureImg4,
};

export const appImages = {
  "app/helpdesk": appFeatureHelpdesk,
  "app/recharge": appFeatureRecharge,
  "app/plan": appFeaturePlan,
  "app/refer-and-earn": appFeatureReferAndEarn,
  "app/water-report": appFeatureWaterReport,
};

export const citiesImages = {
  "city/bengaluru": banglore,
  "city/delhi": delhi,
  "city/faridabad": faridabad,
  "city/ghaziabad": ghaziabad,
  "city/gurgaon": gurgaon,
  "city/hyderabad": hyderabad,
  "city/mumbai": mumbai,
  "city/noida": noida,
  "city/pune": pune,
};

export const backgroundImages = {
  "bg/city-showcase-bg-desk": cityShowcaseBgDesk,
  "bg/testimonial-desk": testimonialsBgDesk,
  "bg/testimonial-mobile": testimonialsBgMobile,
};

export const awardsImages = {
  "awards/1": award1,
  "awards/2": award2,
  "awards/3": award3,
  "awards/4": award4,
  "awards/5": award5,
  "awards/6": award6,
  "awards/7": award7,
  "awards/8": award8,
};

export const investorsImages = {
  "investors/1": investor1,
  "investors/2": investor2,
  "investors/3": investor3,
  "investors/4": investor4,
  "investors/5": investor5,
  "investors/6": investor6,
};

export const uncategorisedImages = {
  "misc/google-playstore": googplePlaystore,
  "misc/apple-appstore": appStore,
  "misc/water-can": waterCan,
  "misc/other-purifier": otherPurifier,
};

export const iconsImages = {
  "icon/whatsapp": WhatsappLogo,
};

export const heroImages = {
  "hero/bengaluru-desktop": bengaluruDesktop,
  "hero/bengaluru-mobile": bengaluruMobile,
  "hero/otherCities-desktop": otherCitiesDesktop,
  "hero/otherCities-mobile": otherCitiesMobile,
  "hero/all-black-mobile": allBlackHeroMobile,
  "hero/all-black-desktop": allBlackHeroDesktop,
};

export const allImages = {
  ...appImages,
  ...heroImages,
  ...iconsImages,
  ...citiesImages,
  ...awardsImages,
  ...investorsImages,
  ...backgroundImages,
  ...uncategorisedImages,
};
