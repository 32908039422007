import { setItemInLocalStorage } from "@src/lib/plans";
import { useEffect } from "react";

const commitHash = "12856e9d5d490be504b48b62c8822195edc0d6b6";

function getItemFromLocalStorage(str: string) {
  try {
    const localObject = localStorage.getItem(str);
    const parsedObject = localObject && JSON.parse(localObject);
    return parsedObject || "";
  } catch (err) {
    console.error("local storage is not accessible:", err);
    return null;
  }
}

function mainOrganicPopup() {
  const localTrackObject = getItemFromLocalStorage("trackRevisit");
  if (localTrackObject == null) {
    //local storage is not accessible
    //incomplete
    showPopup();
  } else if (localTrackObject == "") {
    //form not submitted
    shouldPopupBeShown();
  } else {
    //form submitted
    const maxDays = 30 * 24 * 3600 * 1000;
    const { createdDate } = getItemFromLocalStorage("trackRevisit");
    const todaysDate = new Date();
    if (
      new Date(todaysDate).getTime() - new Date(createdDate).getTime() >
      maxDays
    ) {
      shouldPopupBeShown();
    }
  }
}

function shouldPopupBeShown() {
  const aDayInMs = 24 * 3600 * 1000;
  const maxCount = 5;
  const { count, modifiedDate } =
    getItemFromLocalStorage("organicPopupCounter") || {};

  if (
    modifiedDate == undefined ||
    new Date().getTime() - new Date(modifiedDate).getTime() > aDayInMs
  ) {
    showPopup();
    setItemInLocalStorage("organicPopupCounter", {
      count: 1,
      modifiedDate: `${new Date(new Date().setHours(0, 0, 0, 0))}`,
    });
  } else {
    if (count < maxCount) {
      showPopup();
      setItemInLocalStorage("organicPopupCounter", {
        count: count + 1,
        modifiedDate: `${new Date(new Date().setHours(0, 0, 0, 0))}`,
      });
    }
  }
}

function showPopup() {
  if (document.getElementById("root-popup")) {
    window.setOrganicPopupState(true);
  }

  const bodyEl = document.querySelector("body") as HTMLBodyElement;
  const divEl = document.createElement("div");
  divEl.setAttribute("id", "root-popup"); //change "root" to "root-popup" in index-popup.js and index-popup.css
  const scriptEl = document.createElement("script");
  // scriptEl.setAttribute("src","https://cdn.statically.io/bb/drinkprimetechnology/dp-frontend-cdn/prod/popup-million-react/dist/assets/index-popupForm.js")
  scriptEl.setAttribute(
    "src",
    `https://cdn.statically.io/bb/drinkprimetechnology/dp-frontend-cdn/${commitHash}/popup-million-react/dist/assets/index-popupForm.js`
  );

  scriptEl.addEventListener("load", function () {
    setTimeout(() => {
      const popup = document.getElementById("dp-organic-popup");
      if (popup) {
        if (popup.classList.contains("hidden")) {
          popup.classList.remove("hidden");
        }
      }
    }, 0);
  });

  scriptEl.setAttribute("id", "dp-organic-popup-script");
  scriptEl.setAttribute("type", "module");
  const linkEl = document.createElement("link");
  // linkEl.setAttribute("href","https://cdn.statically.io/bb/drinkprimetechnology/dp-frontend-cdn/prod/popup-million-react/dist/assets/index-popupForm.css")
  linkEl.setAttribute(
    "href",
    `https://cdn.statically.io/bb/drinkprimetechnology/dp-frontend-cdn/${commitHash}/popup-million-react/dist/assets/index-popupForm.css`
  );
  linkEl.setAttribute("rel", "stylesheet");
  linkEl.setAttribute("id", "dp-organic-popup-css");

  bodyEl.appendChild(divEl);
  bodyEl.appendChild(scriptEl);
  bodyEl.appendChild(linkEl);
}

export function useOrganicPopup() {
  useEffect(() => {
    setTimeout(mainOrganicPopup, 5000);
  }, []);

  return null;
}

export default mainOrganicPopup;
