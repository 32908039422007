import { cn } from "@src/lib/utils";
import { CityPopupContext } from "@src/pages/homepage";
import { useContext } from "react";
import { LocationDialogButtonTrigger } from "../cityPopup.tsx/CityPopup";

export function LocationButton({ className }: { className: string }) {
  const { cityPopupSelection, setIsCityPopupOpen } =
    useContext(CityPopupContext);
  return (
    <LocationDialogButtonTrigger
      className={cn(
        "text-xs sm:text-sm text-off-white font-semibold flex gap-1.5 items-center",
        className
      )}
      onClick={() => setIsCityPopupOpen(true)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="18"
        viewBox="0 0 12 18"
        fill="none"
        className="translate-y-[2px] sm:translate-y-[3px]"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.09341 1.44666C4.91412 0.388744 7.15208 0.407235 8.95571 1.49509C10.7416 2.60511 11.827 4.58616 11.8169 6.71722C11.7754 8.8343 10.6115 10.8243 9.15662 12.3628C8.31692 13.2547 7.37757 14.0434 6.35776 14.7127C6.25273 14.7734 6.13768 14.8141 6.01829 14.8327C5.90339 14.8278 5.79149 14.7938 5.69268 14.7339C4.13575 13.7282 2.76984 12.4444 1.66067 10.9443C0.732547 9.69215 0.205253 8.17935 0.150392 6.61136C0.149186 4.47622 1.27269 2.50457 3.09341 1.44666ZM4.14501 7.49479C4.45128 8.24984 5.17419 8.74234 5.97621 8.74235C6.50162 8.74612 7.00669 8.53566 7.37888 8.15787C7.75106 7.78008 7.95944 7.26634 7.95758 6.73112C7.96038 5.91417 7.47942 5.17607 6.73926 4.86145C5.9991 4.54684 5.14574 4.71777 4.57764 5.29443C4.00953 5.8711 3.83874 6.73975 4.14501 7.49479Z"
          fill="#FCFCFC"
        />
        <ellipse
          opacity="0.4"
          cx="5.98307"
          cy="16.4993"
          rx="4.16667"
          ry="0.833333"
          fill="#FCFCFC"
        />
      </svg>
      <span>
        {cityPopupSelection == "Bangalore"
          ? "Bengaluru"
          : cityPopupSelection == "Gurugram"
          ? "Gurgaon"
          : cityPopupSelection || "city"}
      </span>
      <span className="transform rotate-180 scale-x-[2] font-thin -sm:-translate-y-[3px]">
        ^
      </span>
    </LocationDialogButtonTrigger>
  );
}
