import { CityShowcase } from "@src/sections/cityShowcase";
import { DpAppSection } from "@src/sections/dpAppSection";
import { SpotlightSection } from "@src/sections/spotlightSection";
import FormSection from "@src/sections/LeadFormSection/leadFormSection";
import { Testimonials } from "@src/sections/testimonials/testimonials";
import { FaqSection } from "@src/sections/Faqs/faqSection";
import { useEffect, useState } from "react";
import { lpVisitService } from "@src/lib/visitObject";
import SmartWaterOnSubscription from "@src/sections/SmartWaterOnSubscription/SmartWaterOnSubscription";
import HeroSection from "@src/sections/HeroSection/HeroSection";
import { DpAdvantageGrid } from "@src/sections/DpAdvantageGrid/DpAdvantageGrid";
import { Header } from "@src/sections/header/header";
import { Footer } from "@src/sections/footer";
import { ProductPlanSection } from "../../sections/PlanCarouselSection";
import { BannerStrip } from "@src/sections/strips/bannerStrip";
import { bannerStripData } from "@src/sections/strips/data";
import FeatureHighlights from "@src/sections/strips/featureHighlightStrip";
import StickyBar from "@src/components/ui/stickyBar";
import { scrollToId } from "../../lib/utils";
import { createContext } from "react";
import { cityPopupNameType } from "../../sections/header/cityPopup.tsx/utils";
import { getSelectedCityPopupFromLocalStorage } from "../../lib/selectedCityInPopup";
import mainOrganicPopup from "@src/hooks/useOrganicPopup";
import { shouldCityTooltipBeShownByLocalStorage } from "../../sections/header/cityPopup.tsx/CityToolTip";
import { menuLinks } from "@src/sections/header/data";
import { delhiNCRCities } from "@src/const/const";

export const CityPopupContext = createContext<{
  cityPopupSelection: cityPopupNameType | "";
  setCityPopupSelection: React.Dispatch<
    React.SetStateAction<cityPopupNameType | "">
  >;
  isDelhiNcr: boolean;
  isCityPopupOpen: boolean;
  setIsCityPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isCityToolTipOpen: boolean;
  setIsCityToolTipOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onDialogCloseCallback: () => void;
  setOnDialogCloseCallback: React.Dispatch<React.SetStateAction<() => void>>;
}>({
  isDelhiNcr: false,
  cityPopupSelection: "",
  setCityPopupSelection: () => {},
  isCityPopupOpen: true,
  setIsCityPopupOpen: () => {},
  isCityToolTipOpen: false,
  setIsCityToolTipOpen: () => {},
  onDialogCloseCallback: () => {},
  setOnDialogCloseCallback: () => {},
});

export const Homepage = () => {
  const cityFromLocalStorage = getSelectedCityPopupFromLocalStorage();

  const [cityPopupSelection, setCityPopupSelection] = useState<
    cityPopupNameType | ""
  >(cityFromLocalStorage || "Bangalore");
  const [isCityPopupOpen, setIsCityPopupOpen] = useState(false);
  const [isCityToolTipOpen, setIsCityToolTipOpen] = useState(
    shouldCityTooltipBeShownByLocalStorage
  );
  const [onDialogCloseCallback, setOnDialogCloseCallback] = useState(
    () => () => {}
  );
  useEffect(() => {
    lpVisitService("homepage");
    const timerId: NodeJS.Timeout = setTimeout(() => {
      mainOrganicPopup();
    }, 5000);

    return () => clearTimeout(timerId);
  }, []);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            setIsCityToolTipOpen(false);
            observer.disconnect();
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 1,
      }
    );
    const target = document.querySelector("#intersection_tracker");
    if (target) {
      observer.observe(target);
    }

    return () => {
      observer.disconnect();
    };
  }, []);
  useEffect(() => {
    const scroll_widget = document.getElementById("scroll-widget");
    const whatsapp_icon = document.getElementById("sticky-whatsapp-icon");
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          scroll_widget?.classList.toggle("visible", !entry.isIntersecting);
          whatsapp_icon?.classList.toggle("visible", !entry?.isIntersecting);
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 1,
      }
    );
    const target = document.querySelector("#intersection_tracker");
    if (target) {
      observer.observe(target);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectedCity: any = cityPopupSelection || "Bangalore";

  const isDelhiNcr = delhiNCRCities.includes(selectedCity);

  return (
    <CityPopupContext.Provider
      value={{
        isDelhiNcr,
        cityPopupSelection,
        setCityPopupSelection,
        isCityPopupOpen,
        setIsCityPopupOpen,
        isCityToolTipOpen,
        setIsCityToolTipOpen,
        onDialogCloseCallback,
        setOnDialogCloseCallback,
      }}
    >
      <Header menuLinks={menuLinks} isShowCityPopup />
      <HeroSection />
      <div className="hidden md:block">
        <BannerStrip stripData={bannerStripData} />
      </div>
      <div className="md:hidden">
        <BannerStrip stripData={[...bannerStripData, ...bannerStripData]} />
      </div>
      <StickyBar />
      <FeatureHighlights />
      <SmartWaterOnSubscription />
      <ProductPlanSection />
      <DpAdvantageGrid />
      <Testimonials />
      <DpAppSection />
      <SpotlightSection />
      <CityShowcase />
      <FormSection />
      <FaqSection />
      <HowItWorksButton />
      <Footer />
    </CityPopupContext.Provider>
  );
};

function HowItWorksButton() {
  return (
    <div className="py-3 px-4 bg-primary-background border-b">
      <button
        type="button"
        onClick={() => scrollToId("how-it-works-section")}
        className="font-bold leading-[200%] text-center underline underline-offset-2 text-primary-primary -sm:text-sm -sm:leading-[150%] cursor-pointer w-full"
      >
        Click here to know how to subscribe and how the app works
      </button>
    </div>
  );
}
