const GENERAL_FAQS = [
  {
    faq: "Why is subscribing to a DrinkPrime water purifier for home better than buying?",
    desc: "Subscribing to home water purification eliminates upfront costs, AMC, and service charges. Enjoy hassle-free access to pure drinking water.",
  },
  {
    faq: "Can I modify or upgrade my water purifier subscription plan later?",
    desc: "Yes, enjoy flexibility in modifying or upgrading your water purifier subscription plan using the DrinkPrime mobile app based on your changing needs.",
  },
  {
    faq: "How does DrinkPrime ensure prompt RO water purifier service?",
    desc: "Enjoy hassle-free after-sales RO water purifier service with DrinkPrime at no additional cost. Our IoT technology constantly monitors water quality, triggering proactive RO water purifier service alerts. The DrinkPrime mobile app aids in diagnosing water dispensing issues, while our call center and company-operated technician network ensure speedy resolutions.",
  },
  {
    faq: "How does DrinkPrime's RO UV water purifier subscription work?",
    desc: "Subscribe easily on our website, and enjoy the installation of water purifier within 48 hours. Choose a plan based on your water consumption, and benefit from lifetime free maintenance and filter changes (based on flow rate).",
  },
  {
    faq: "How does the quality of DrinkPrime water compare to that of water cans?",
    desc: "DrinkPrime's RO water purifier for home offers superior quality compared to cans. Many water cans contain harmful bacteria, and plastic leaching can lead to adverse health effects. DrinkPrime ensures the highest safety standards through advanced filtration and IoT technology monitoring.",
  },
  {
    faq: "Can I trust the quality of water purified by DrinkPrime?",
    desc: "Absolutely! Our RO UV water purifier undergoes rigorous testing, and continuous IoT monitoring ensures safe and pure drinking water without compromising essential minerals.",
  },
  {
    faq: "Will this DrinkPrime RO UV water purifier retain essential minerals?",
    desc: "Yes, our RO UV water purifier is designed to retain essential minerals. Our mineralizer enriches your water, ensuring it's not only safe and pure but also enriched with necessary minerals for your well-being.",
  },
  {
    faq: "Are there additional charges for RO installation, delivery, or filter changes?",
    desc: "No, there are no extra charges for RO installation or delivery. Enjoy lifetime free maintenance and personalized changes based on your consumption pattern and flow rate.",
  },
  {
    faq: "Can we cancel the DrinkPrime subscription any time?",
    desc: (
      <>
        <p className="mb-2">
          Yes, you can cancel your DrinkPrime subscription at any time once you
          have completed the 3-month lock-in period from your date of
          installation.
        </p>
        <p className="mb-2">
          We also provide a 7-day free trial option that allows you to explore
          our services at no cost. During this trial period, you can cancel
          without any charges. After the trial, if you choose to continue, the
          3-month lock-in period will be in effect.
        </p>
      </>
    ),
  },
  {
    faq: "Will I get a 100% refund of my security deposit?",
    desc: (
      <>
        <p className="mb-2">
          Yes. You will receive a full refund of your security deposit upon
          cancellation.
        </p>
        <p className="mb-2">
          At the time of cancellation, if there is a default on your monthly
          payments, the outstanding dues will be deducted from your security
          deposit, and the remaining balance will be refunded to you. To know
          the exact deductible amount, please reach out to our customer support
          team, who would be happy to assist you.
        </p>
        <p>
          If you cancel your DrinkPrime subscription before the 3-month lock-in
          period, the remaining months will be considered a default, and your
          security deposit will be refunded accordingly. For example, if you
          cancel your subscription after the first 2 months, the subscription
          charge for the 3rd month will be considered a default.
        </p>
      </>
    ),
  },
];

const PLANS_FAQS = [
  {
    faq: "Will my water purifier subscription balance be carried forward?",
    desc: "Your balance will be carried forward until you continue using the same water purifier subscription plan. The balance gets reset when you change plans.",
  },
  {
    faq: "Do I still have to pay if I'm not at home/using DrinkPrime water purifier?",
    desc: "Getting DrinkPrime water purifier on rent is just like renting a home for you and your family; Just like you pay your house rent, you’ll have to pay your current DrinkPrime plan amount as the monthly rent for your water purifier. This helps us continue to provide you with safe drinking water, and we hope you understand! In case you are travelling or are out of home and want to pause your subscription, we will extend your DrinkPrime water purifier subscription validity for you at a special price of Rs. 250, irrespective of your plan. However, you can avail of this 28-day validity extension feature only once every 360 days of usage.",
  },
  {
    faq: "What is the minimum tenure for water purifier subscription?",
    desc: "We do not have a minimum contract tenure for water purifier subscriptions. You can disconnect anytime by giving us a 30-day notice period.",
  },
  {
    faq: "What documents do you require for the installation of water purifier?",
    desc: "We would need your ID and address proof for the installation of the water purifier. A rental agreement is considered valid address proof.",
  },
  {
    faq: "What are the RO installation charges?",
    desc: "We offer free RO installation and relocation for our purifiers.",
  },
  {
    faq: "Is there any deposit required?",
    desc: "We take Rs 1500/- as a refundable security deposit. The deposit is completely refundable once you return the water purifier.",
  },
];

const DEVICE_FAQS = [
  {
    faq: "Does this require drilling/change in my water and power lines?",
    desc: "More than 85% of our customers choose to use the water purifier as a wall-hanging unit. However, you can choose to use the unit both as a wall-hanging and a countertop unit. Our experienced RO installation team will take care of all the necessary power/water line changes required during the process.",
  },
  {
    faq: "How much will be the electricity and water consumption for the device?",
    desc: "An RO UV water purifier consumes approximately 50 – 60 watts, which is quite low. To put it in perspective, that’s 1/4th the power consumed by most modern 5-star refrigerators.",
  },
  {
    faq: "What is the home water purification and tank capacity?",
    desc: "DrinkPrime has a home water purification capacity of 15 litres/hour and a storage tank capacity of 8 litres.",
  },
  {
    faq: "Will it work on my input water?",
    desc: "Our water purifier for home works optimally till a Hardness (TDS) of 1500 PPM. As a part of our RO Installation process, we make sure that the quality of your Input (Raw) Water is up to the standards where it can be purified. Then, we will proceed with the Installation of water purifier.",
  },
  {
    faq: "What quality certification do you have?",
    desc: "Our ideal water purifier holds ISO certifications for quality standards. Our water quality is certified by leading laboratories.",
  },
];

const PRODUCTS_FAQS = [
  {
    faq: "What are the key features of the DrinkPrime Alkaline water purifier?",
    desc: "The DrinkPrime Alkaline purifier comes fully loaded with a multistage purification process that includes alkaline water purification, reverse osmosis purification and UV purification.",
  },
  {
    faq: "Is a Copper RO water purifier better than a normal RO water purifier?",
    desc: "A copper water purifier offers several benefits compared to a standard RO purifier. Copper is an essential mineral needed by the human body. The DrinkPrime Copper RO Water Purifier has a copper filter that infuses water with copper. According to the Indian Council of Medical Research (ICMR), consuming 2 liters of water charged with copper daily can provide around 30% of the recommended dietary allowance (RDA) for this vital mineral.",
  },
  {
    faq: "Is using a Copper water purifier safe?",
    desc: "Yes, using a copper water purifier is completely safe as copper is one of the most important minerals that benefit the human body. Copper can help you reduce weight, avoid inflammation, and improve your overall health. Copper purifiers are effective at killing bacteria in water. When you purchase Pureit’s copper water purifier, you are also providing your body with a variety of health benefits.",
  },
  {
    faq: "Usually copper water purifiers are very costly. Is the DrinkPrime Copper RO purifier going to be costly too?",
    desc: "Absolutely not. The DrinkPrime Copper RO water purifier’s subscription plans start at just Rs.333/month.",
  },
  {
    faq: "Is a Copper water purifier good for your health?",
    desc: "Yes, copper water purifiers provide 100% safe drinking water by removing all bacteria, viruses, and harmful germs while adding the goodness of copper to your drinking water. DrinkPrime Copper RO water purifier is one such purifier that helps enhance your overall health and well-being.",
  },
  {
    faq: "What are the key features of the DrinkPrime Copper RO water purifier?",
    desc: "The DrinkPrime Copper RO water purifier comes fully loaded with a multistage purification process that includes reverse osmosis purification, UV purification and a copper filter.",
  },
  {
    faq: "How is mineral addition done in DrinkPrime Mineral+?",
    desc: "In the DrinkPrime Mineral+ water purifier, mineral addition is done through a mineral cartridge.",
  },
  {
    faq: "What is the advantage of mineral-infused water through DrinkPrime Mineral+?",
    desc: "Usually, most RO water purifiers over-purify or under-purify drinking water. In the DrinkPrime Mineral+ water purifier, mineral addition is done through a mineral cartridge so that minerals in your purified drinking water are not lost.",
  },
  {
    faq: "What are the key features of the DrinkPrime Mineral+ water purifier?",
    desc: "The DrinkPrime Mineral+ RO water purifier has a 4-stage purification process and comes with a compact 8-liter storage tank. It is equipped with a mineral cartridge which aids in mineral addition to your purified drinking water.",
  },
];

const SERVICE_FAQS = [
  {
    faq: "What to expect during an RO water purifier service visit?",
    desc: "During an RO water purifier service visit, our technicians conduct a thorough inspection, clean and sanitize the system, make any necessary replacements or adjustments, and provide valuable maintenance recommendations for optimal functionality and longevity, ensuring customer satisfaction.",
  },
  {
    faq: "What if I am facing issues with the water purifier on rent?",
    desc: "In case of any issues with your RO purifier for home, please switch it off and request a visit from our water-wellness expert via the mobile app. We will visit your place and get it rectified.",
  },
  {
    faq: "What if I do not feel the water taste up to the mark?",
    desc: "If you have been using Bottled Water for a long time, please give it a couple of days to adjust to the taste of your better water purifier. If you want the taste to be adjusted still, please create an Issue through the App and our team, and we will schedule an RO water purifier service visit.",
  },
  {
    faq: "How often do you service my Water Purifier for home?",
    desc: (
      <>
        <p>
          We service your water purifier best according to the parameters below:
        </p>
        <ul className="list-inside list-disc [&>li]:ml-4 my-2">
          <li>Quality of your Input Water</li>
          <li>Volume of Water processed by your Purifier</li>
          <li>Duration since the Last Service.</li>
        </ul>
        <p>
          You'll receive a Maintenance Due alert on the DrinkPrime mobile app,
          triggered by the health status of your water purifier and your water
          consumption patterns. All you need to do is schedule the maintenance
          check at your convenience.
        </p>
      </>
    ),
  },
];

const TECHNOLOGY_APP_FAQS = [
  {
    faq: "What if My water purifier subscription balance/validity gets over?",
    desc: "Before your Balance / Validity is over, you will receive a Notification / SMS prompting you to recharge the water purifier subscription. You can do so through the App.",
  },
  {
    faq: "I am not at home. Can my family still continue to use the water purifier?",
    desc: "Absolutely. We advise syncing your water purifier with the app every 15 days. Anybody can sync the purifier by downloading the App and entering the Purifier ID.",
  },
  {
    faq: "Do I need to download your App to use the water purifier?",
    desc: "Yes, you would need to download the App to view your real-time consumption details, Make Payments and Schedule Proactive Maintenance Activities.",
  },
  {
    faq: "How do you use IoT to make me feel safe about the water I drink?",
    desc: "Our remote health monitoring algorithm continuously monitors the device's health. We use this information to schedule proactive maintenance activities, ensuring water purifier’s health.",
  },
];

const SECURITY_DEPOSIT = [
  {
    faq: "I have raised a request for uninstallation. How will my refund amount be calculated?",
    desc: (
      <>
        <p>
          We operate on a prepaid Water Purifier on Subscription Model, meaning
          once payment is made for a particular month, there's no refund for any
          reason, including Uninstallation/Unsubscription. Please refer to the
          example for detailed calculations.
        </p>
        <h3 className="font-semibold underline text-sm mt-2">
          For Water Purifier Monthly Subscription Plan:
        </h3>
        <ul className="list-inside list-disc [&>li]:ml-4 my-2">
          <li>Recharge Date: 01-03-2024</li>
          <li>Total Recharge Amount: ₹399</li>
          <li>Uninstallation Request Date: 15-03-2024</li>
          <li>Due Subscription Amount: ₹399</li>
          <li>Balance Subscription Refund: ₹0</li>
        </ul>
        <p>
          At the end of a water purifier subscription, any security deposit and
          excess balance applicable for long-term plans will be refunded. Here's
          how the refund calculation works:
        </p>
        <h3 className="font-semibold underline text-sm mt-2">
          For Long-Term Water Purifier Subscription Plan:
        </h3>
        <ul className="list-inside list-disc [&>li]:ml-4 my-2">
          <li>Recharge Duration: 6 Months (5 Months + 1 Month free)</li>
          <li>Total Recharge Amount: ₹1795</li>
          <li>Months of Usage (including month of uninstallation): 3</li>
          <li>Due Subscription Amount (3 Months @ ₹333): ₹1077</li>
          <li>Balance Subscription to be refunded: ₹718</li>
        </ul>
      </>
    ),
  },
  {
    faq: "When will my security deposit be refunded?",
    desc: "The security deposit will be reimbursed to the customer within 5 working days upon device pickup. Any outstanding dues from the customer, including unpaid water purifier subscription charges, will be deducted from the security deposit. Additionally, the device will undergo inspection for any damage or abuse, with exceptions for normal wear and tear. If any damage or abuse is identified, the corresponding amount will be deducted from the security deposit.",
  },
];

export const FaqsCategoryData = {
  general: {
    title: "General",
    faqs: GENERAL_FAQS,
  },
  plans: {
    title: "Plans",
    faqs: PLANS_FAQS,
  },
  device: {
    title: "Device",
    faqs: DEVICE_FAQS,
  },
  product: {
    title: "Products",
    faqs: PRODUCTS_FAQS,
  },
  service: {
    title: "Service",
    faqs: SERVICE_FAQS,
  },
  technology: {
    title: "App",
    faqs: TECHNOLOGY_APP_FAQS,
  },
  securityDeposit: {
    title: "Security Deposit",
    faqs: SECURITY_DEPOSIT,
  },
} as const;
