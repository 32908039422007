export const linkSections = {
  premiumDrinkingWater: [
    // { title: "Plans", path: "#plans-section" },
    {
      title: "Plans",
      path: [
        "#plan_section",
        "#pay-per-litre",
        "#plans-section",
        "#rent-now-sticky-scroll",
        "https://www.drinkprime.in/app/checkout/chooseplan",
      ],
    },
    {
      title: "How it works",
      path: ["#how-it-works", "#how-it-works-section", "/app/how-it-works"],
    },
    { title: "DrinkPrime advantage", path: "#drinkprime-advantage-section" },
    { title: "Customer stories", path: "#testimonials-section" },
    {
      title: "Our Products",
      path: [
        "#product-plan-section",
        "#plans-section",
        "https://www.drinkprime.in/app/bengaluru/ro-uv-copper-water-purifier-on-rent",
      ],
    },
    // { title: "Customer stories", path: "#testimonials-section" },
    // { title: "Get 7-days risk free trial", path: "#free-trial-section" },
  ],
  explore: [
    { title: "About", path: "https://www.drinkprime.in/about" },
    { title: "Careers", path: "https://drinkprime.in/careers" },
    { title: "Blog", path: "https://www.drinkprime.in/blog" },
    { title: "FAQ's", path: ["#faqs", "#faqs-section", "/app/faqs"] },
    { title: "Contact", path: ["#contact-us", "/app/contact-us"] },
  ],
  referAndEarn: [
    { title: "Refer now", path: "https://drinkprime.in/app/refer_earn" },
  ],

  terms: [
    {
      title: "Privacy policy",
      path: "https://www.drinkprime.in/app/privacy-policy",
    },
    {
      title: "Terms of use",
      path: "https://www.drinkprime.in/app/terms-of-use",
    },
  ],
};

export const footerCities = [
  {
    title: "[product] Water purifier in Bengaluru",
    path: "https://drinkprime.in/app/[city]/ro-uv-[product]-water-purifier-on-rent",
  },
  {
    title: "[product] Water purifier in Hyderabad",
    path: "https://drinkprime.in/app/[city]/ro-uv-[product]-water-purifier-on-rent",
  },
  {
    title: "[product] Water purifier in Delhi",
    path: "https://drinkprime.in/app/[city]/ro-uv-[product]-water-purifier-on-rent",
  },
  {
    title: "[product] Water purifier in Gurgaon",
    path: "https://drinkprime.in/app/[city]/ro-uv-[product]-water-purifier-on-rent",
  },
  {
    title: "[product] Water purifier in Ghaziabad",
    path: "https://drinkprime.in/app/[city]/ro-uv-[product]-water-purifier-on-rent",
  },
  {
    title: "[product] Water purifier in Faridabad",
    path: "https://drinkprime.in/app/[city]/ro-uv-[product]-water-purifier-on-rent",
  },
  {
    title: "[product] Water purifier in Noida",
    path: "https://drinkprime.in/app/[city]/ro-uv-[product]-water-purifier-on-rent",
  },
  {
    title: "[product] Water purifier in Mumbai",
    path: "https://drinkprime.in/app/[city]/ro-uv-[product]-water-purifier-on-rent",
  },
  {
    title: "[product] Water purifier in Pune",
    path: "https://drinkprime.in/app/[city]/ro-uv-[product]-water-purifier-on-rent",
  },
];
