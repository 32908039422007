export type DelhiNCRCities =
  | "Delhi"
  | "Ghaziabad"
  | "Gurugram"
  | "Noida"
  | "Faridabad";

// array of delhi ncr cities
export const delhiNCRCities: DelhiNCRCities[] = [
  "Delhi",
  "Ghaziabad",
  "Gurugram",
  "Noida",
  "Faridabad",
];
