import { linkSections } from "./data";
import classNames from "clsx";
import { Icons, Logos } from "@src/components/icons";
import { Images } from "@src/components/images";
import LeadFormPopupTriggerButton from "../LeadFormSection/LeadFormPopup/LeadFormPopupTriggerButton";
import { useLinkHandler } from "@src/hooks/useLinkHandler";
import { CityList } from "./CityList";

// Link section data

export const Footer = () => {
  const handleLinkClick = useLinkHandler();
  return (
    <>
      <CityList />
      <footer className="!bg-primary-primary  [&_a]:cursor-pointer mb-[72px] md:mb-[84px]">
        <div className="md:container md:mx-auto py-10 px-4 lg:px-20 text-white ">
          <div className="flex flex-col lg:flex-row justify-between gap-2 xl:gap-16">
            <div className=" md:max-w-[315px]">
              <Logos
                name="dp-white"
                className="w-[223px] h-[50px] md:w-[158px] md:h-[36px]"
              />
              <p className="text-xs font-bold my-3">
                Smart Purifiers on Rent. Free Maintenance for Life.
              </p>
              <p className=" leading-tight tracking-tight">
                #9/A, Chukki complex, 19th Main Rd, Sector 3, HSR layout,
                Bengaluru, Karnataka 560102
              </p>
              <div className="hidden lg:block">
                <SocialsLinks />
              </div>
            </div>
            <div className="lg:hidden mt-[52px] mb-8">
              <SocialsLinks />
            </div>
            <div className="flex flex-col gap-[40px] md:grid lg:grid-cols-[repeat(3,auto)] md:gap-10 xl:gap-20">
              <div className="">
                <h3 className="font-semibold text-xl mb-4 text-accent-accent whitespace-pre">
                  Premium Drinking Water
                </h3>
                <ul>
                  {linkSections.premiumDrinkingWater.map((link, index) => (
                    <li key={index} className="mb-[8px]">
                      <a
                        onClick={() => handleLinkClick(link)}
                        className="font-medium hover:underline"
                      >
                        {link.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="">
                <h3 className="font-semibold text-xl mb-4 text-accent-accent">
                  Explore
                </h3>
                <ul>
                  {linkSections.explore.map((link, index) => (
                    <li key={index} className="mb-2">
                      <a
                        onClick={() => handleLinkClick(link)}
                        className="font-medium hover:underline"
                      >
                        {link.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="">
                <h3 className="font-semibold text-xl mb-4 text-accent-accent">
                  Terms
                </h3>
                <ul>
                  {linkSections.terms.map((link, index) => (
                    <li key={index} className="mb-2">
                      <a
                        onClick={() => handleLinkClick(link)}
                        className="font-medium hover:underline"
                      >
                        {link.title}
                      </a>
                    </li>
                  ))}
                </ul>
                <h3 className="font-semibold mt-10 md:mt-6 mb-4 text-accent-accent">
                  Refer & Earn
                </h3>
                <ul>
                  {linkSections.referAndEarn.map((link, index) => (
                    <li key={index} className="mb-2">
                      <a
                        onClick={() => handleLinkClick(link)}
                        className="font-medium hover:underline"
                      >
                        {link.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[#3133A0] font-medium py-10 text-white">
          <div className="container flex justify-between  px-4 md:pr-24 2xl:pr-0">
            <p className="text-sm md:text-base">
              © 2025 DrinkPrime. All rights reserved.
            </p>
            <div className="hidden md:block">
              <SocialsLinks hideAppBtn />
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

const SocialsLinks = ({ hideAppBtn = false }: { hideAppBtn?: boolean }) => {
  return (
    <>
      <div
        className={classNames(
          "flex space-x-4",
          hideAppBtn ? "" : "lg:mt-[60px] mb-4 "
        )}
      >
        <a href="https://twitter.com/drinkprime?lang=en" target="_blank">
          <Icons name="twitter" className="h-[25px]" color="#FFFF" />
        </a>
        <a
          href="https://www.linkedin.com/company/drinkprime/?originalSubdomain=in"
          target="_blank"
        >
          <Icons name="linkedin" className="h-[25px]" color="#FFFF" />
        </a>
        <a href="https://www.facebook.com/DrinkPrime/" target="_blank">
          <Icons name="facebook" className="h-[25px]" color="#FFFF" />
        </a>
        <a href="https://www.instagram.com/drink_prime/?hl=en" target="_blank">
          <Icons name="instagram" className="h-[25px]" color="#FFFF" />
        </a>
        <LeadFormPopupTriggerButton
          className="h-[25px]"
          formName="homepage/footer-whatsapp-cta"
          nextRoute={() => {
            setTimeout(() => (window.location.href = "https://wa.link/61q8wq"));
          }}
        >
          <Icons name="whatsapp" className="h-[25px]" color="#FFFF" />
        </LeadFormPopupTriggerButton>
        <a href="https://www.youtube.com/@DrinkPrime" target="_blank">
          <Icons name="youtube" className="w-[25px] h-[25px]" color="#FFFF" />
        </a>
      </div>
      {!hideAppBtn && (
        <div className="flex space-x-4 mt-9 ">
          <a
            href="https://apps.apple.com/in/app/drinkprime/id1358111884"
            target="_blank"
          >
            <Images
              name="misc/apple-appstore"
              className="w-[126px] h-[35px]"
              alt="Apple app store logo"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=waterwala.com.prime"
            target="_blank"
          >
            <Images
              name="misc/google-playstore"
              className="w-[126px] h-[35px]"
              alt="Google play store logo"
            />
          </a>
        </div>
      )}
    </>
  );
};
