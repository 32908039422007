import { scrollToId } from "@src/lib/utils";
import { useCallback } from "react";

export const useLinkHandler = () => {
  return useCallback((link: { path: string | string[] }, offset?: number) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    Array.isArray(link.path)
      ? link.path.every((link) => {
          if (link.startsWith("#") && document.getElementById(link.slice(1))) {
            scrollToId(link.slice(1), offset);
            return false;
          } else if (link.startsWith("http")) {
            window.location.href = link;
            return false;
          } else if (link.startsWith("/")) {
            const url = link.includes("chooseplan")
              ? link.includes("show111")
                ? link
                : link.includes("?")
                ? `${link}&show111=true`
                : `${link}?show111=true`
              : link;
            navigateTo(url);

            return false;
          }
          return true;
        })
      : link?.path?.startsWith("#")
      ? scrollToId(link.path.slice(1), offset)
      : link?.path?.includes("http")
      ? (window.location.href = link.path ?? "")
      : navigateTo(link.path ?? "");
  }, []);
};

function navigateTo(url: string) {
  window.location.href = url;
}
